import React, {Component} from 'react';
import './App.css';
import Dialog from "./components/Dialog";
const connectionStates={
  DISCONNECTED: 'Disconnected',
  CONNECTING: 'Connecting',
  CONNECTED: 'Connected',
  AWAITINGCONTROL: 'Awaiting Control',
  CONTROLLING: 'Controlling',
};

class App extends Component{
  constructor(props) {
    super(props);
    this.state={
      messages: [],
      chat: '',
      controlsAllowed: false,
      status: connectionStates.DISCONNECTED,
      displayDialog: true,
      userCount: 0,
      queueNumber: 0,
      videoDims: null,
      requestedControl: true,
      roombaOffline: false,
    };
    this.controlsAllowed = false;
    this.iceConfiguration = {
      iceServers: [
        {
          credential: 'imajion',
          username: 'im',
          urls: "turn:104.198.65.122",
        },
      ],
    };
    this.messages = [];
    this.dataChannel = null;
    this.moving = false;
    this.name = '';
    this.socketId = null;
    this.terminated = false;
    this.vacuum = false;
    this.video = null;
    this.webrtc = null;
    this.scroll = null;
    this.websocket = null;
    this.sentHasControlMessage=false
  };

  enqueueMessage=(message)=>{
    this.messages.push(message);
    this.setState({
      messages: this.messages
    },()=>{
      this.scroll.scrollTop =  this.scroll.scrollHeight;
    });
  };

  generateOffer=()=>{
    this.webrtc = new RTCPeerConnection(this.iceConfiguration);
    this.webrtc.oniceconnectionstatechange = this.iceStateMonitor;
    this.webrtc.onicecandidate = (event) => {
      if (event.candidate) {
        this.sendWebsocketMessage({
          type: 'p2pCandidate',
          candidate: event.candidate,
          toRoomba: true
        });
      }
    };
    this.webrtc.addTransceiver('audio');
    this.webrtc.addTransceiver('video');
    this.webrtc.ontrack = this.gotRemoteStream;
    let things = {offerToReceiveAudio: true, offerToReceiveVideo: true};
    this.dataChannel = this.webrtc.createDataChannel('data');
    this.webrtc.createOffer(things)
      .then((offer) => {
        return this.webrtc.setLocalDescription(offer);
      })
      .then(() => {
        this.sendWebsocketMessage({
          type: 'p2pOffer',
          offer: this.webrtc.localDescription,
          socketId: this.socketId
        });
      })
      .catch((error) => {
        console.log('Conference offer failure: ', error);
      });
  };

  gotRemoteStream=(e)=>{
    this.video.srcObject =  e.streams[0];
    setTimeout(()=>{
      if(this.video && this.video.videoWidth)
        this.setState({
          videoDimensions: {height: this.video.videoHeight, width: this.video.videoWidth}
        })
    },100)
  };

  keyListener=(event)=>{
    if(event.type === `keyup`){
      switch(event.code){
        case `ArrowUp`:
        case `ArrowDown`:
          if(!this.moving) return;
          this.moving = false;
          this.sendDCMessage('stopForward');
          break;
        case `ArrowLeft`:
        case `ArrowRight`:
          if(!this.moving) return;
          this.moving = false;
          this.sendDCMessage('stopTurn');
          break;
        case `Space`:
          if(!this.vacuum)return;
          this.vacuum = false;
          this.sendDCMessage('stopVac');
          break;
        case `Enter`:
          this.sendChat();
          break;
        default:
          break;
      }
    }
    else{
      switch(event.code){
        case `ArrowUp`:
          if(this.moving) return;
          this.moving = true;
          this.sendDCMessage('forward');
          break;
        case `ArrowDown`:
          if(this.moving) return;
          this.moving = true;
          this.sendDCMessage('back');
          break;
        case `ArrowLeft`:
          if(this.moving) return;
          this.moving = true;
          this.sendDCMessage('left');
          break;
        case `ArrowRight`:
          if(this.moving) return;
          this.moving = true;
          this.sendDCMessage('right');
          break;
        case `Space`:
          if(this.vacuum) return;
          this.vacuum = true;
          this.sendDCMessage('startVac');
          break;
        default:
          break;
      }
    }
  };

  sendDCMessage=(data)=>{
    if(!this.controlsAllowed) return;
    const message = {type: data};
    console.log(message);
    this.dataChannel.send(JSON.stringify(message))
  };

  //<editor-fold desc="iceStateMonitor">
  //monitors the ice state for the duration of the call
  iceStateMonitor=()=>{
    if(!this.webrtc){
      return
    }
    console.log('ICE ' + this.webrtc.iceConnectionState);

    switch (this.webrtc.iceConnectionState) {
      case 'checking':
        break;
      case 'closed':
        break;
      case 'completed':
      case 'connected':
        if(this.state.status !== connectionStates.CONNECTED){
          window.addEventListener('keydown', this.keyListener);
          window.addEventListener('keyup', this.keyListener);
          this.setState({
            status: connectionStates.CONNECTED
          })
        }
        break;
      case 'disconnected':
      case 'failed':
        if(this.state.status !== connectionStates.DISCONNECTED){
          window.removeEventListener('keydown', this.keyListener);
          window.removeEventListener('keyup', this.keyListener);
          this.setState({
            status: connectionStates.DISCONNECTED
          });
          this.video.srcObject = null;
          this.webrtc.close();
          this.webrtc = null;
        }
        break;
      case 'new':
        break;
      default:
        break;
    }
  };
  //</editor-fold>

  onIceCandidate=(data)=>{
    if (this.webrtc !== null && data.candidate) {
      this.webrtc.addIceCandidate(new RTCIceCandidate(data.candidate))
        .catch(e=>{
          console.log('Error adding ice candidate: ', e)
        });
    }
  };

  //<editor-fold desc="onWebsocketClose">
  //fires when p2p WSS closes
  onWebsocketClose = ()=> {
    if (!this.terminated) {
      console.log("reconnecting");
      this.initializeWebsocketConnection(this.socketId);
      return;
    }
    console.log("P2P connection closed")
  };
  //</editor-fold>

  //<editor-fold desc="onWebsocketError">
  //fires if p2p WSS errors
  onWebsocketError = (data)=> {
    console.log('Slack WSS error: ', data)
  };
  //</editor-fold>

  //<editor-fold desc="onWebsocketMessage">
  //parses and sorts messages from Slack
  onWebsocketMessage = (message)=> {
    const data = JSON.parse(message.data);
    console.log("Websocket message: ", data);
    const type = data.type;
    switch(type){
      case 'queueUpdate':
        for(let i = 0; i< data.queue.length; i++) {
          if (data.queue[0] === this.socketId) {
            //you are in control
            this.controlsAllowed = true;
            if(!this.sentHasControlMessage){
              const message = {
                type: 'chat',
                message: this.name + " has the controls!"
              };
              this.sentHasControlMessage = true;
              this.sendWebsocketMessage(message);
            }
            this.setState({
              requestedControl: false,
              controlsAllowed: true
            });
            return;
          }
          //you are not in control
          if (data.queue[i] === this.socketId) {
            this.sentHasControlMessage = false;
            this.setState({
              queueNumber: i
            })
          }
        }
        if(this.state.controlsAllowed || this.controlsAllowed){
          this.controlsAllowed = false;
          this.setState({
            controlsAllowed: false
          })
        }
        break;
      case 'userCount':
        this.setState({
          userCount: data.number
        });
        if(data.message){
          this.enqueueMessage(data.message)
        }
        break;

      case 'chat':
        if(data.message === 'Mr.Roomba joined the S U C C'){

        }
        this.enqueueMessage(data.message);
        break;
      case 'p2pCandidate':
        this.onIceCandidate(data);
        break;
      case 'p2pAnswer':
        this.webrtc.setRemoteDescription(data.answer);
        break;
      case 'p2pHangup':
        if(this.webrtc){
          this.webrtc.close();
          this.video.srcObject = null;
          this.setState({
            status: connectionStates.DISCONNECTED
          })
        }
        break;
      case 'socketId':
        this.socketId = data.socketId;
        this.generateOffer();
        this.setState({
          status: connectionStates.CONNECTING
        });
        break;
      case 'error':
        if(data.message === 'Roomba not found'){
          this.setState({
            roombaOffline: true
          })
        }
        break;
      default:
        console.log("Invalid Message: ", type)
    }
  };
  //</editor-fold>

  //<editor-fold desc="onWebsocketOpen">
  //runs when slack WSS opens
  onWebsocketOpen(){
    console.log("Websocket Open");
  };
  //</editor-fold>

  //<editor-fold desc="sendWebsocketMessage">
  //sends a message over WSS
  sendWebsocketMessage(message){
    try{
      this.websocket.send(JSON.stringify(message))
    }
    catch(e){
      console.log('Error sending p2p message', e)
    }
  };
  //</editor-fold>

  //<editor-fold desc="terminate">
  //nukes the websocket connection
  terminate(){
    this.terminated = true;
    if(this.websocket){
      this.websocket.close();
    }
  };
  //</editor-fold>

  sendChat=()=>{
    if(this.state.chat === '') return;
    const message = {
      type: 'chat',
      message: this.name + ": " + this.state.chat
    };
    this.sendWebsocketMessage(message);
    this.setState({
      chat: ''
    })
  };

  requestControl=()=>{
    const message = {
      type: 'requestControl'
    };
    this.sendWebsocketMessage(message);
    this.setState({
      requestedControl: true
    })
  };

  initializeWebsocketConnection=(name)=>{
    if(name && !this.name) this.name = name;
    if(!name && this.name) name = this.name;
    this.setState({
      displayDialog: false
    });
    // this.websocket = new WebSocket('ws://localhost:8080?name='+encodeURIComponent(name));
    this.websocket = new WebSocket('wss://room-272823.appspot.com?name='+encodeURIComponent(name));
    this.websocket.onclose = this.onWebsocketClose;
    this.websocket.onerror = this.onWebsocketError;
    this.websocket.onmessage = this.onWebsocketMessage;
    this.websocket.onopen = this.onWebsocketOpen;
  };

  render(){
    return (
      <div className="app">
        {this.state.displayDialog &&
          <Dialog
            initializeWebsocketConnection={this.initializeWebsocketConnection}
          />
        }
        <div className='container'>
          <div className='videoContainer'>
            {this.state.roombaOffline &&
              <div className='roombaCharging'>
                <p>Roomba is charging, check back later!!!</p>
              </div>
            }
            <video src={''} ref={video=>this.video = video} autoPlay={true} playsInline={true}/>
          </div>
          <div className='videoOverlay' style={this.state.status !== connectionStates.CONNECTED  ? {display: 'none'} : {}}>
            <div className='videoOverlayMessages' ref={div=>this.scroll=div}>
              {this.state.messages.map(message=>{
                return(
                  <div className='videoOverlayMessage'>
                    <p>{message}</p>
                  </div>
                )
              })}
            </div>
            <div className='videoOverlayChat'>
              <input autoFocus={true} placeholder='Chat' value={this.state.chat} onChange={e=>{this.setState({chat:e.target.value})}}/>
              <div className='videoOverlayChatButton' onClick={this.sendChat}>
                <p>Send</p>
              </div>
            </div>
            <div className='videoOverlayControls'>
              <div className='videoOverlayControlTopContainer'>
                <p>{this.state.userCount} users are online!</p>
                {this.state.requestedControl && <p>Number {this.state.queueNumber} in the queue</p>}
              </div>
              <div className='videoOverlayControlContainer'>
                {this.state.controlsAllowed &&
                  <div className='videoOverlayControlEnabled'>
                    <p>You have control!</p>
                  </div>
                }
                {!this.state.controlsAllowed && !this.state.requestedControl &&
                  <div className='videoOverlayControlRequest' onClick={this.requestControl}>
                    <p>Request control</p>
                  </div>
                }
                {this.state.requestedControl &&
                  <div className='videoOverlayControlRequest'>
                    <p>Control Requested</p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default App;
